<template>
  <div class="container bg-first p-1">
    <b-card>
      <div>
        <h3 class="m-0">
          โปรไฟล์ของฉัน
        </h3>
      </div>

      <b-row>
        <b-col lg="8">
          <div class="card-profile-lot mt-2">
            <!-- tab profile and invite friend -->
            <div class="card-profile__tab">
              <div class="row">
                <div class="col-md-4">
                  <div class="card-profile__tab-item active">
                    <h4>โปรไฟล์</h4>
                  </div>
                  <figure class="lottery-profile-image text-center">
                    <b-avatar
                      size="135px"
                      :src="require('@/assets/images/avatars/avatar.png')"
                      style="border: 4px solid #facf2a;"
                    />
                  </figure>
                </div>
                <div class="col-8">
                  <article class="lottery-content font">
                    <dl class="row">
                      <dt class="col-xl-3">
                        ชื่อ-นามสกุล
                      </dt>
                      <dd class="col-xl-9">
                        {{ userdata.name }} {{ userdata.surname }}
                      </dd>

                      <dt class="col-xl-3">
                        เบอร์โทรศัพท์
                      </dt>
                      <dd class="col-xl-9">
                        {{ userdata.tel }}
                      </dd>

                      <!-- <dt class="col-xl-3">
                        LINE ID
                      </dt> -->
                      <!-- <dd class="col-xl-9">
                        <a
                          href="https://access.line.me/oauth2/v2.1/authorize?response_type=code&amp;client_id=1656983305&amp;redirect_uri=https%3A%2F%2Fbeta.mekonglottery.co%2Fline_link.php&amp;bot_prompt=aggressive&amp;state=356032&amp;scope=profile%20openid"
                          class="btn btn-sm btn-success"
                        >เชื่อมต่อด้วย Line</a>
                        <p><small>เพิ่มการเชื่อมต่อไปยังไลน์ของคุณ เพื่อให้สามารถเข้าสู่ระบบด้วยไลน์ได้ และเมื่อเพิ่มเพื่อนแล้วสามารถรับข่าวสาร หรือการแจ้งเตือนต่าง ๆ ผ่านทางไลน์</small></p>
                      </dd> -->

                      <dt class="col-xl-3">&nbsp;
                      </dt>
                      <dd class="col-xl-9">&nbsp;
                      </dd>
                    </dl>
                  </article>
                </div>
              </div>

            </div>
            <!-- divider -->
            <div class="card-profile__divider" />
            <article>
              <div class="d-flex justify-content-between mb-1">
                <h4 class="lottery-subtitle">
                  ข้อมูลบัญชีของฉัน
                </h4>
              </div>
              <article class="lottery-content font">
                <dl class="row mb-0">
                  <dt class="col-xl-3">
                    ธนาคาร
                  </dt>
                  <dd class="col-xl-9">
                    <span
                      class="bg-bnk"
                      :style="`background: ${userdata.bg};`"
                    >
                      <img
                        :src="`/bankIcon/${userdata.path_photo}`"
                        alt="bank"
                        height="25"
                      >
                    </span>{{ userdata.bankname }}
                  </dd>
                  <dt class="col-xl-3">
                    เลขที่บัญชี
                  </dt>
                  <dd class="col-xl-9">
                    {{ userdata.acc_no }}
                  </dd>

                  <dt class="col-xl-3">
                    ชื่อบัญชี
                  </dt>
                  <dd class="col-xl-9">
                    {{ userdata.name }} {{ userdata.surname }}
                  </dd>
                </dl>
              </article>
            </article>

            <div class="card-profile__divider" />
            <article v-if="userdata.usertype === '3'">
              <div class="d-flex justify-content-between">
                <h4 class="lottery-subtitle">
                  ลิงค์แนะนำลูกค้า
                </h4>
              </div>

              <b-alert
                v-clipboard:copy="message3"
                variant="primary"
                show
              >
                <div
                  class="alert-body d-flex justify-content-between cursor-pointer"
                  @click="copy2"
                >
                  <span>https://sabaideelotto.com/register?ref={{ userdata.invite_code }}&type=3</span>
                  <span><i class="fas fa-copy" /></span>
                </div>
              </b-alert>
              <vue-qrcode
                :value="`https://sabaideelotto.com/register?ref=${userdata.invite_code}&type=3`"
              />

            </article>
            <article v-else>
              <div class="d-flex justify-content-between">
                <h4 class="lottery-subtitle">
                  ลิงค์แนะนำลูกค้า
                </h4>
              </div>

              <b-alert
                v-clipboard:copy="message1"
                variant="primary"
                show
              >
                <div
                  class="alert-body d-flex justify-content-between cursor-pointer"
                  @click="copy2"
                >
                  <span>https://sabaideelotto.com/register?ref={{ userdata.invite_code }}&type=2</span>
                  <span><i class="fas fa-copy" /></span>
                </div>
              </b-alert>
              <vue-qrcode
                :value="`https://sabaideelotto.com/register?ref=${userdata.invite_code}&type=2`"
              />

            </article>

            <article v-if="!userdata.usertype">
              <div class="d-flex justify-content-between mt-2">
                <h4 class="lottery-subtitle">
                  ลิงค์แนะนำซัพเอเย่นต์
                </h4>
              </div>

              <b-alert
                v-clipboard:copy="message2"
                variant="warning"
                show
              >
                <div
                  class="alert-body d-flex justify-content-between cursor-pointer"
                  @click="copy"
                >
                  <span>https://sabaideelotto.com/register?ref={{ userdata.invite_code }}&type=1</span>
                  <span><i class="fas fa-copy" /></span>
                </div>
              </b-alert>
              <vue-qrcode
                :value="`https://sabaideelotto.com/register?ref=${userdata.invite_code}&type=1`"
              />
            </article>
          </div>
        </b-col>
        <b-col lg="4">
          <div class="card-profile-lot mt-2">
            <!-- tab profile and invite friend -->
            <div class="card-profile__tab">
              <h3>ทางลัด</h3>
              <a
                href="/cart"
                class="lottery-button link-shortcut mb-1"
              >ตะกร้าของฉัน</a>
              <a
                href="/history"
                class="lottery-button link-shortcut mb-1"
              >ประวัติการสั่งซื้อ</a>
            </div>
          </div>

          <div class="card-profile-lot mt-2">
            <!-- tab profile and invite friend -->
            <div class="card-profile__tab">
              <article class="lottery-card mb-md-0">
                <h4 class="lottery-subtitle">
                  ยอดเงินคงเหลือ
                </h4>
                <dl class="row mb-0">
                  <dt class="col-xl-8">
                    กระเป๋าเงิน
                  </dt>
                  <dd class="col-xl-4">
                    {{ Commas(balance) }} บาท
                  </dd>

                  <dt
                    v-if="UserData.role === 'agent'"
                    class="col-xl-8"
                  >
                    กำไรหรือขาดทุน
                  </dt>
                  <dd
                    v-if="UserData.role === 'agent'"
                    class="col-xl-4"
                  >
                    {{ Commas(balance - LastDeposit.amount) }} บาท
                  </dd>

                  <!-- <dt class="col-xl-8">
                    โบนัส
                  </dt>
                  <dd class="col-xl-4">
                    0
                  </dd> -->
                </dl>
                <div class="row mt-2">
                  <div class="col-6">
                    <a
                      href="/deposit-withdraw"
                      class="w-100 lottery-button link-shortcut"
                    >ฝากเงิน</a>
                  </div>
                  <div class="col-6">
                    <a
                      href="/deposit-withdraw"
                      class="w-1000 lottery-button link-shortcut"
                    >ถอนเงิน</a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card v-if="!UserData.usertype">
      <div class="row">
        <div class="col-md-10 mb-1">
          <h3 class="m-0">
            รายชื่อซัพเอเย่นต์ ({{ inviteDataSub.length }}) คน
          </h3>
          <small>รางวัลรายได้จากการแนะนำ หรือชวนเพื่อนมาสมัคร</small>
        </div>
      </div>
      <div v-if="sum > 0">
        มาจากซัพเอเย่นต์อื่น {{ sum }} ใบ
      </div>
      <div v-else>
        มาจากซัพเอเย่นต์อื่น 0 ใบ
      </div>

      <div
        v-for="item in inviteDataSub"
        :key="item"
        class="card-profile-lot2 mt-1 d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="55px"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            style="border: 2px solid #facf2a; margin-right: 10px;"
          />

          <div>
            <h5 class="mb-0">
              {{ item.tel }}
            </h5>
            <small>{{ item.name }}  {{ item.surname }}</small>
          </div>
        </div>

        <div>
          จำนวน  {{ item.sublist }} ใบ
        </div>
      </div>
    </b-card>

    <b-card v-if="UserData.usertype === '1'">
      <div class="row">
        <div class="col-md-10 mb-1">
          <h3 class="m-0">
            รายชื่อเพื่อนที่แนะนำ ({{ inviteData.length }}) คน
          </h3>
          <small>รางวัลรายได้จากการแนะนำ หรือชวนเพื่อนมาสมัคร</small>
        </div>

        <div class="col-md-2 mb-1">
          <b-button
            variant="primary"
            block
            class="float-right"
            @click="reward()"
          >
            รับเงินชวนเพื่อน {{ InviteReward }} บาท
          </b-button>
        </div>
      </div>

      <div
        v-for="item in inviteData"
        :key="item.username"
        class="card-profile-lot2 mt-1 d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <b-avatar
            size="55px"
            :src="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            style="border: 2px solid #facf2a; margin-right: 10px;"
          />

          <div>
            <h5 class="mb-0">
              {{ item.tel }}
            </h5>
            <small>{{ item.name }}  {{ item.surname }}</small>
          </div>
        </div>

        <div>
          จำนวน  {{ item.total ? item.total : 0 }} ใบ
          <!-- <b-button variant="primary">
            รับ 15 บาท
          </b-button> -->
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BAlert, BButton,
} from 'bootstrap-vue'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    VueQrcode,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BAlert,
    BButton,
  },
  data() {
    return {
      balance: JSON.parse(localStorage.getItem('balance')),
      UserData: JSON.parse(localStorage.getItem('userData')),
      number: 1,
      selectNum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
      ],
      userdata: null,
      message1: '',
      message2: '',
      message3: '',
      inviteData: null,
      InviteReward: null,
      LastDeposit: null,
      inviteDataSub: null,
      sum: 0,
    }
  },
  async mounted() {
    await this.GetUser()
    if (this.UserData.usertype === '1') {
      this.GetInvite()
      this.GetInviteReward()
    } else if (!this.UserData.usertype) {
      this.GetInviteAgent()
    }
    this.GetLastDeposit()
  },
  methods: {
    reward() {
      this.$swal({
        title: 'แจ้งเตือน ?',
        text: 'ยืนยันที่จะรับเงินแนะนำเพื่อนหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          this.reward2()
        } else {
          this.show = false
          this.$swal('ยกเลิก', 'ยกเลิกการรับเงินแนะนำ !', 'error')
        }
      })
    },
    reward2() {
      if (this.InviteReward <= 0) {
        this.$swal({
          icon: 'warning',
          title: '<h3 style="color: #FFF">ขออภัยค่ะ!! ยอดแนะนำเพื่อนของท่านเหลือ 0 บาท</h3>',
          showConfirmButton: false,
          timer: 3000,
          background: '#202124',
        })
      } else {
        this.$http
          .post('https://api.sabaideelotto.com/api/invite/ReceiveReward', {
            lotto: this.lotto,
          })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: '<h3 style="color: #FFF">รับเงินแนะนำเพื่อนสำเร็จ</h3>',
              showConfirmButton: false,
              timer: 2000,
              background: '#202124',
            })
            this.getBalance()
          }).catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    GetInvite() {
      let index = 0
      this.$http
        .get('https://api.sabaideelotto.com/api/invite/BySubagent')
        .then(async ress => {
          // console.log(ress.data)
          this.inviteData = ress.data
          ress.data.forEach(element => {
            this.countByuser(element.username, index)
            index += 1
          })
          console.log(this.inviteData)
        })
    },
    countByuser(username, index) {
      const obj = {
        username,
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/getCountByusername', obj)
        .then(res => {
          // console.log(res.data)
          this.inviteData[index].count = res.data
          this.inviteData[index].total = res.data
        })
    },
    GetInviteAgent() {
      this.$http
        // .get('http://127.0.0.1:8888/api/invite/Byagent')
        .get('https://api.sabaideelotto.com/api/invite/Byagent')
        .then(ress => {
          // console.log(ress.data)
          this.inviteDataSub = ress.data.user
          this.sum = ress.data.lotto_total
        })
    },
    GetLastDeposit() {
      this.$http
        .post('https://api.sabaideelotto.com/api/GetLastDeposit')
        .then(ress => {
          // console.log(ress.data)
          this.LastDeposit = ress.data
        })
    },
    GetInviteReward() {
      this.$http
        .get('https://api.sabaideelotto.com/api/invite/GetInviteReward')
        .then(ress => {
          // console.log(ress.data)
          this.InviteReward = ress.data
        })
    },
    GetUser() {
      this.$http
        .get('https://api.sabaideelotto.com/api/lottery/user/show')
        .then(ress => {
          // console.log(ress.data)
          this.userdata = ress.data
          this.message1 = `https://sabaideelotto.com/register?ref=${this.userdata.invite_code}&type=2`
          this.message2 = `https://sabaideelotto.com/register?ref=${this.userdata.invite_code}&type=1`
          this.message3 = `https://sabaideelotto.com/register?ref=${this.userdata.invite_code}&type=3`
        })
    },
    getBalance() {
      this.$http
        .get('https://api.sabaideelotto.com/api/zean/balance/getbalanceuser')
        .then(ress => {
          // console.log(ress.data)
          this.balance = ress.data.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    copy() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งแนะนำเอเย่นต์สำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    copy2() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกลิ้งแนะนำเพื่อนสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
  },
  head() {
    return {
      title: 'รายการสั่งซื้อสลากฯ ทั้งหมดของคุณ',
    }
  },
}
</script>

<style scoped>
.lottery-button.link-shortcut {
    border-radius: 10px;
    color: #1d0a07;
    background-color: #facf2a;
    display: block;
    text-align: center;
    font-family: "IBM Plex Sans Thai", sans-serif;
    padding: 0.625rem;
    text-decoration: none;
    font-size: 18px;
}
.card-profile__divider {
    border-bottom: 2px dotted #00351a;
    margin: 0 0 1.25rem;
}
.bg-bnk {
    border-radius: 6px;
              width: 35px;
              height: 35px;
              padding: 5px;
              margin-right: 10px;
}
</style>
